<template>
  <b-card
    no-body
  >
    <b-card-body>
      <b-card-title>Información del contrato</b-card-title>
      <b-card-sub-title>Este modulo puede ver los datos asociados a tu contrato</b-card-sub-title>
      <hr>
      <b-alert
        variant="danger"
        :show="userData && userData.contrato == null"
      >
        <h4 class="alert-heading">
          Este usuario no posee datos de contrato
        </h4>
      </b-alert>
      <validation-observer
        ref="TravelerForm"
      >
        <vue-contract
          v-if="userData && userData.contrato != null"
          :contrac-data="userData"
          @input-n-contrato="setTipoCargo"
          @input-fecha-contrato="setTipoCargo"
          @input-total-price="setTipoCargo"
          @input-cuotas-pago="setTipoCargo"
          @input-observaciones="setTipoCargo"
          @input-contrato-pais-id="setTipoCargo"
          @input-contrato-ciudad-id="setTipoCargo"
          @input-contrato-municipio-id="setTipoCargo"
          @input-tipo-contrato-id="setTipoCargo"
          @input-tipo-regalo-id="setTipoCargo"
          @input-user-id-vendedor="setTipoCargo"
          @input-fecha-pago="setTipoCargo"
        />
      </validation-observer>
      <footer>
        <div class="w-100">
          <b-button
            v-if="$can('update', 'contrato') && userData && userData.contrato != null"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="float-right ml-1"
            @click="updateContract"
          >
            Actualizar
          </b-button>
        </div>
      </footer>
    </b-card-body>
  </b-card>
</template>

<script>
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import { ValidationObserver } from 'vee-validate'
import {
  BCard, BCardBody, BCardTitle, BCardSubTitle, BButton, BAlert,
} from 'bootstrap-vue'
import IndexContract from '@/views/users/contract/ContractEdit.vue'
import Ripple from 'vue-ripple-directive'
import {
  required, integer, alpha, alphaSpaces, email, ext,
} from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import userStoreModule from '@/views/users/user/userStoreModule'

export default {
  name: 'Traveler',
  components: {
    ValidationObserver,
    BCard,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BButton,
    BAlert,
    'vue-contract': IndexContract,
  },
  directives: {
    Ripple,
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-user'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })
  },
  data() {
    return {
      // viaje
      carrera_estudio: null,
      carrera_estudio_segunda_opcion: null,
      institucion_academica: null,
      fecha_terminacion: null,
      preparatoria: null,
      lugar_nacimiento: null,
      tarjeta_identidad: null,
      expedicion: null,
      pasaporte: null,
      celular: null,
      telefono: null,
      direccion: null,
      date_of_birth: null,
      pasp_expedicion: null,
      pasp_vencimiento: null,
      visa_americana: null,
      tipo_gender: null,
      tipo_documento_id: null,
      municipio_id: null,
      ciudad_id: [],
      pais_id: null,
      tipo_financiacion_id: null,
      tipo_estudio_id: null,
      ciudad_universidad_id: null,
      ciudad_universidad_segunda_id: null,
      universidad_id: null,
      universidad_segunda_id: null,
      fecha_viaje_id: null,
      tipo_stado_civil_id: null,
      tipo_grado_academico_id: null,

      userData: null,
      // validation rules
      required,
      integer,
      alpha,
      alphaSpaces,
      email,
      ext,
    }
  },
  mounted() {
    this.setDataUser()
  },
  methods: {
    setDataUser() {
      const userId = JSON.parse(localStorage.getItem('userData'))
      store.dispatch('app-user/fetchUser', { id: userId.id })
        .then(response => {
          this.userData = response.data.userData
        })
        .catch(error => {
          if (error.response.status === 404) {
            this.userData = undefined
          }
        })
    },
    setTipoCargo(value) {
      this.[value.key] = value.info
    },
    updateContract() {
      const dataFechasPago = {
        fecha_pago: this.fecha_pago,
      }
      this.$http.put(`/v1/fechas-pago/${this.userData.contrato.id}`, dataFechasPago).then(res => {//eslint-disable-line
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Se ha actualizado satisfactoriamente',
            icon: 'CheckCircleIcon',
            variant: 'success',
            text: 'El registro se ha actualizado.',
          },
        },
        {
          position: 'top-center',
        })
      }).catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error',
            icon: 'XIcon',
            variant: 'danger',
            text: error.data[0].message,
          },
        },
        {
          position: 'bottom-right',
        })
      })
    },
  },
}
</script>

<style>

</style>
